import { useState } from 'react';
import './App.css';
import HistoryData from './date.json';


// 問題選択
function random(arr, num) {
  let arrLength = arr.length;
  let t = [];
  let r = [];
  while(num-- > 0) {
    const randomNum = Math.random() * arrLength | 0;
    r[arrLength] = t[randomNum] || arr[randomNum];
    --arrLength;
    t[randomNum] = t[arrLength] || arr[arrLength];
  }
  return r;
}

const oldData = random(HistoryData, 4);
const data = oldData.filter((e) => {return e !== ''});


// 正答の順番
const dataLength1 = HistoryData.indexOf(data[0]);
const dataLength2 = HistoryData.indexOf(data[1]);
const dataLength3 = HistoryData.indexOf(data[2]);
const dataLength4 = HistoryData.indexOf(data[3]);

let numData = [dataLength1, dataLength2, dataLength3, dataLength4]
const sortedData = numData.concat().sort((a, b) => a - b )

const sortedData1 = numData.indexOf(sortedData[0]) + 1;
const sortedData2 = numData.indexOf(sortedData[1]) + 1;
const sortedData3 = numData.indexOf(sortedData[2]) + 1;
const sortedData4 = numData.indexOf(sortedData[3]) + 1;

const clickedData = [];

function App() {
  // 回答を入力

  let [ownAnswer, setOwnAnswer] = useState([]);
  let [answer, setAnswer] = useState([]);

  function btnClick(num) {
    clickedData.push(num);
    ownAnswer = String(clickedData).replace(/,/g, '  ');
    setOwnAnswer(ownAnswer);

    if(clickedData.length === 4) {
      answer = `${sortedData1}  ${sortedData2}  ${sortedData3}  ${sortedData4}`;
      setAnswer(answer);
    }
  }


  return (
    <div className="App">
        <a className="header" href="/">
          <p className="header-text">ならべかえ</p>
        </a>
      <div className="content">
        <div className="card" onClick={() => btnClick(1)}>
          <p>1: {data[0]}</p>
        </div>
        <div className="card" onClick={() => btnClick(2)}>
          <p>2: {data[1]}</p>
        </div>
        <div className="card" onClick={() => btnClick(3)}>
          <p>3: {data[2]}</p>
        </div>
        <div className="card" onClick={() => btnClick(4)}>
          <p>4: {data[3]}</p>
        </div>
        <div className="answers">
          <p>解答: {ownAnswer}</p>
          <p>正答: <span className="answer">{answer}</span></p>
        </div>
      </div>
    </div>
  );
}

export default App;
